import { phoneMask } from 'helpers';
import IcPhone from 'assets/icons/phone.png';
import IcHome from 'assets/icons/home.png';
import IcMail from 'assets/icons/email.png';

import * as S from './styles';
import { useMemo } from 'react';

export const TemplateCurriculum = ({ curriculo, className }) => {
  const possibleTransports = (transport) => {
    const transports = [
      { value: 'nao-possui', label: 'Não possui' },
      { value: 'carro', label: 'Carro' },
      { value: 'moto', label: 'Moto' },
    ];
    return transports.find((t) => t.value === transport);
  };
  return (
    <S.Wrapper className={className}>
      <div className="row">
        {curriculo?.image && curriculo?.image.length > 0 ? <S.ImageProfile src={curriculo?.image} alt="" /> : null}
        <S.Contact>
          <S.Name>{curriculo?.nome}</S.Name>
          <div>
            <ul>
              <p>
                <strong>CONTATOS</strong>
              </p>
              <li>
                <img src={IcPhone} alt="" width={18} height={18} />
                <strong>Telefone: {phoneMask(curriculo?.dados?.celular || curriculo?.dados?.telefone)}</strong>
              </li>
              <li>
                <img src={IcHome} alt="" width={18} height={18} />
                <strong>Endereço: </strong>
                {`${curriculo?.endereco?.logradouro || ''}, ${curriculo?.endereco?.numero || ''}, ${
                  curriculo?.endereco?.cidade || ''
                }, ${curriculo?.endereco?.cep || ''}, ${curriculo?.endereco?.bairro}, ${
                  curriculo?.endereco?.complemento || ''
                }`}
              </li>
              <li>
                <img src={IcMail} alt="" width={18} height={18} />
                <strong>E-mail: </strong>
                {curriculo?.dados?.email}
              </li>
              <p>
                <strong>URL da página profissional: </strong>
                {curriculo?.complemento?.url}
              </p>
              <p>
                <strong>Data de nascimento: </strong>
                {curriculo?.dados?.data_nascimento}
              </p>
              {curriculo?.dados?.portador_deficiencia && (
                <p>
                  <strong>PCD: </strong>
                  {curriculo?.dados?.deficiencia}
                </p>
              )}
            </ul>
          </div>
        </S.Contact>
      </div>

      <S.Title>Resumo</S.Title>
      <S.Text className="justify">{curriculo?.resumo_profissional?.resumo}</S.Text>

      <S.Title>Formação Acadêmica</S.Title>
      {curriculo?.formacao_academica?.map((formacao) => (
        <div key={formacao.id} style={{ marginTop: 8 }}>
          <S.Text>
            <strong>{`${formacao.data_inicio} - ${formacao.data_fim ? formacao.data_fim : 'Hoje'}`}</strong>
          </S.Text>
          <S.Text>
            <strong>{formacao.descricao}</strong>
          </S.Text>
          <S.Text>{formacao.instituicao}</S.Text>
        </div>
      ))}

      <S.Title>Experiência Profissional</S.Title>
      {curriculo?.experiencia_profissional?.map((experiencia) => (
        <div key={experiencia.id} style={{ marginTop: 8 }}>
          <div>
            <S.Text>
              <strong>{experiencia.empresa}</strong>
            </S.Text>
            <S.Text>{experiencia.funcao}</S.Text>
            {experiencia.data_inicio && (
              <S.Text>
                Período: {`${experiencia.data_inicio} - ${experiencia.data_fim ? experiencia.data_fim : 'Hoje'}`}
              </S.Text>
            )}
          </div>
        </div>
      ))}

      <S.Title>Cursos</S.Title>
      {curriculo?.curso?.map((curso) => (
        <div key={curso.id} style={{ marginTop: 8 }}>
          <S.Text>
            <strong>
              {curso.nome} {curso.observacao ? `- ${curso.observacao}` : `- ${curso.instituicao || ''}`}
            </strong>
          </S.Text>
          {curso.data_inicio && (
            <S.Text>Período: {`${curso.data_inicio} - ${curso.data_fim ? curso.data_fim : 'Hoje'}`}</S.Text>
          )}
          <S.Text>Carga horária: {curso.carga_horaria}h</S.Text>
        </div>
      ))}

      <S.Title>Idiomas</S.Title>
      {curriculo?.idioma?.map((idioma) => (
        <div key={idioma.id}>
          <S.Text>
            <strong>{idioma.nome}</strong> - {idioma.nivel}
          </S.Text>
        </div>
      ))}

      <S.Title>Complementos</S.Title>
      <S.Text>
        <strong>E-mail alternativo: </strong>
        {curriculo?.complemento?.email_alternativo}
      </S.Text>
      <S.Text>
        <strong>Turno pretendido: </strong>
        {curriculo?.complemento?.turno}
      </S.Text>
      <S.Text>
        <strong>Disponibilidade para viagem: </strong>
        {curriculo?.complemento?.viagem ? 'Sim' : 'Não'}
      </S.Text>
      <S.Text>
        <strong>CNH: </strong>
        {curriculo?.complemento?.cnh ? curriculo?.complemento?.categoria_cnh : 'Não possui CNH'}
      </S.Text>
      <S.Text>
        <strong>Transporte: </strong>
        {possibleTransports(curriculo?.complemento?.transporte)?.label}
      </S.Text>
      <S.Text>
        <strong>Estrangeiro: </strong>
        {curriculo?.complemento?.estrangeiro ? 'Sim' : 'Não'}
      </S.Text>
    </S.Wrapper>
  );
};
