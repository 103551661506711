import { useEffect, useState, useMemo } from 'react';
import { BlueButton, Switch } from 'components/atoms';
import { TemplateAlunos } from 'components/templates';
import { useProcessos, useAuth } from 'hooks';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { headers, baseUrl } from 'api';
import { toast } from 'react-toastify';

import * as S from './styles';

export const VagaDetalheAluno = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [isRegistering, setIsRegistering] = useState();
  const isSubscribed = searchParams.get('inscrito') === 'true';
  const navigate = useNavigate();
  const { getVaga, vaga } = useProcessos();
  const { user } = useAuth();
  const [dados, setDados] = useState({
    requisitos: false,
    cidade_bairro: false,
  });
  const [daysOrdered, setDaysOrdered] = useState(null);
  const daysOrder = useMemo(() => [
    'segunda-feira',
    'terça-feira',
    'quarta-feira',
    'quinta-feira',
    'sexta-feira',
    'sábado',
    'domingo',
  ]);

  useEffect(() => {
    if (vaga?.dias) {
      const daysSorted = vaga.dias.sort((a, b) => {
        return daysOrder.indexOf(a.dia.trim()) - daysOrder.indexOf(b.dia.trim());
      });
      setDaysOrdered(daysSorted);
    }
  }, [vaga, daysOrder, setDaysOrdered]);

  useEffect(() => {
    if (id) getVaga(id);
  }, [id]);

  const handleChangeSwitch = (e) => {
    const { name, checked } = e.target;
    setDados({
      ...dados,
      [name]: checked,
    });
  };

  const handleSubscribe = () => {
    setIsRegistering(true);
    fetch(`${baseUrl}/processo-aluno/`, {
      method: 'POST',
      headers: {
        ...headers,
        authorization: sessionStorage.getItem('@senac:token'),
      },
      body: JSON.stringify({
        obs: null,
        requisitos: dados.requisitos,
        ciente: dados.cidade_bairro,
        usuario: user.id,
        processo: vaga.id,
      }),
    })
      .then((response) => {
        if (response.ok) {
          toast.success('Inscrição realizada com sucesso!');
          navigate('/alunos/vagas');
        } else {
          toast.error(`Erro ao inscrever vaga! (${response.status})`);
          setIsRegistering(false);
        }
      })
      .catch(() => {
        toast.error('Erro ao inscrever vaga!');
        setIsRegistering(false);
      });
  };

  return (
    <TemplateAlunos title="PORTAL DO CANDIDATO">
      <S.Title>{vaga?.nome_vaga}</S.Title>

      <S.Content>
        <div className="row">
          <div>
            <h2>Descrição:</h2>
            <p>{vaga?.observacao}</p>
          </div>
        </div>
        <S.Separator />

        <h1>Processo Seletivo</h1>
        <div className="row">
          <div>
            <h2>Data Expiração</h2>
            <p>{vaga?.data_expiracao}</p>
          </div>
          <div>
            <h2>Áreas de Interesses</h2>
            {vaga?.areas_interesses?.map((area) => (
              <p>- {area?.nome}</p>
            ))}
          </div>
          <div>
            <h2>Cidade</h2>
            <p>{vaga?.cidade?.nome}</p>
          </div>
        </div>
        <S.Separator />

        <h1>Vaga</h1>
        <div className="row">
          <div>
            <h2>Função</h2>
            <p>{vaga?.atividade}</p>
          </div>
          <div>
            <h2>Quantidade de Vagas</h2>
            <p>{vaga?.qtd_vagas}</p>
          </div>
        </div>
        <div className="row">
          <div>
            <h2>Valor Mínimo:</h2>
            <p>
              {Number(vaga?.valor_minimo).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
              })}
            </p>
          </div>
          <div>
            <h2>Valor Máximo:</h2>
            <p>
              {Number(vaga?.valor_maximo).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
              })}
            </p>
          </div>
        </div>
        <div className="row">
          {/* <div>
            <h2>Salário:</h2>
            <p>
              {Number(vaga?.valor_salario).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
              })}
            </p>
          </div> */}
          <div>
            <h2>Dias e Horários:</h2>
            {daysOrdered ? (
              daysOrdered.map((dia) => {
                if (dia.horario_fim !== '00:00' || dia.horario_inicio !== '00:00') {
                  return (
                    <p key={dia.id}>
                      {dia.dia}: {dia.horario_inicio} às {dia.horario_fim}
                    </p>
                  );
                }
              })
            ) : (
              <p>Não informado</p>
            )}
          </div>
          <div>
            <h2>Tipo de Vaga</h2>
            <p>{vaga?.tipo_vaga.replace('_', ' ')}</p>
          </div>
        </div>
        <S.Separator />

        <h1>Benefícios</h1>
        <div className="row">
          <div>
            <h2>Vale Transporte</h2>
            <p>
              <strong>{vaga?.vale_transporte ? 'SIM' : 'NÃO'}</strong>
            </p>
            <p>
              {Number(vaga?.valor_transporte).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                style: 'currency',
                currency: 'BRL',
              })}{' '}
              / dia
            </p>
          </div>
          <div>
            <h2>Vale Alimentação/Refeição</h2>
            <p>
              <strong>{vaga?.vale_alimentacao ? 'SIM' : 'NÃO'}</strong>
            </p>
            <p>
              {Number(vaga?.valor_alimentacao).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                style: 'currency',
                currency: 'BRL',
              })}{' '}
              / dia
            </p>
          </div>
          <div>
            <h2>Assistência Médica</h2>
            <p>
              <strong>{vaga?.assistencia_medica ? 'SIM' : 'NÃO'}</strong>
            </p>
            <p>{vaga?.obs_assistencia_medica}</p>
          </div>
        </div>
        <S.Separator />

        <h1>Pré-Requisitos</h1>
        <div className="row">
          <div>
            <h2>Escolaridade:</h2>
            <p>{vaga?.escolaridade}</p>
          </div>
          <div>
            <h2>Habilidades:</h2>
            <p>{vaga?.atividade}</p>
          </div>
          <div />
        </div>
        <S.Separator />
        {!isSubscribed ? (
          <>
            <Switch
              text="Possuo todos os requisitos exigidos na vaga?"
              id="1"
              name="requisitos"
              onChange={handleChangeSwitch}
              checked={dados.requisitos}
            />
            <Switch
              text="Estou ciente sobre a cidade para onde a vaga está destinada?"
              id="2"
              name="cidade_bairro"
              onChange={handleChangeSwitch}
              checked={dados.cidade_bairro}
            />

            <BlueButton
              onClick={() => handleSubscribe()}
              disabled={!dados.requisitos || !dados.cidade_bairro || isRegistering}
            >
              Fazer Inscrição
            </BlueButton>
          </>
        ) : null}
      </S.Content>
    </TemplateAlunos>
  );
};
