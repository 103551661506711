import { useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card } from 'components/atoms';
import { Loading } from 'components/molecules';
import { TemplateAdmin } from 'components/templates';
import { useProcessos } from 'hooks';

import Comment from 'assets/icons/comment.svg';
import ArrowLeft from 'assets/icons/arrow-left.svg';

import * as S from './styles';

export const VagaDetalhe = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { vaga, getVaga } = useProcessos();
  const { id } = useParams();
  const [daysOrdered, setDaysOrdered] = useState(null);
  const daysOrder = useMemo(() => [
    'segunda-feira',
    'terça-feira',
    'quarta-feira',
    'quinta-feira',
    'sexta-feira',
    'sábado',
    'domingo',
  ]);

  useEffect(() => {
    if (vaga?.dias) {
      const daysSorted = vaga.dias.sort((a, b) => {
        return daysOrder.indexOf(a.dia.trim()) - daysOrder.indexOf(b.dia.trim());
      });
      setDaysOrdered(daysSorted);
    }
  }, [vaga, daysOrder, setDaysOrdered]);

  const getInitialData = async () => {
    setIsLoading(true);
    await getVaga(id);
    setIsLoading(false);
  };

  const filterTurnos = (turno) => {
    const turnoSplitted = turno.replaceAll(' ', '').split(',');
    const uniqueTurnos = [...new Set(turnoSplitted)];
    return uniqueTurnos.join(', ');
  };

  useEffect(() => {
    getInitialData(id);
  }, []);

  return (
    <>
      <TemplateAdmin page="Dashboard">
        <S.Cards>
          <Card blue>
            <img src={Comment} alt="" />
            <p>Vagas</p>
          </Card>
        </S.Cards>

        <S.Curriculo>
          <div className="row space-between">
            <h1>{vaga?.nome_vaga}</h1>
            <a onClick={() => navigate(-1)}>
              <img src={ArrowLeft} alt="" />
              Voltar
            </a>
          </div>
          <S.Separator />

          <h2>Atividades a serem executadas</h2>
          <p>{vaga?.atividade}</p>
          <S.Separator />

          <h2>Processo Seletivo</h2>
          <div className="row" style={{ alignItems: 'baseline' }}>
            <div>
              <h3>Data Expiração</h3>
              <p>{vaga?.data_expiracao}</p>
            </div>
            <div>
              <h3>Áreas de interesses</h3>
              {vaga?.areas_interesses?.map((area) => (
                <p>- {area?.nome}</p>
              ))}
            </div>
            <div>
              <h3>Município Destino</h3>
              <p>{vaga?.cidade?.nome}</p>
            </div>
          </div>
          <S.Separator />

          <h2>Vaga</h2>
          <div className="row">
            <div>
              <h3>Quantidade de Vagas</h3>
              <p>{vaga?.qtd_vagas}</p>
            </div>
            <div>
              <h3>Valor Mínimo</h3>
              <p>
                {Number(vaga?.valor_minimo).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  style: 'currency',
                  currency: 'BRL',
                })}
              </p>
            </div>
            <div>
              <h3>Valor Máximo</h3>
              <p>
                {Number(vaga?.valor_maximo).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  style: 'currency',
                  currency: 'BRL',
                })}
              </p>
            </div>
          </div>
          <div className="row">
            {/* <div>
              <h3>Salário</h3>
              <p>
                {Number(vaga?.valor_salario).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  style: 'currency',
                  currency: 'BRL',
                })}
              </p>
            </div> */}
            {daysOrdered?.length > 0 && (
              <div>
                <h3>Dias e Horários</h3>
                {daysOrdered ? (
                  daysOrdered.map((dia) => {
                    if (dia.horario_fim !== '00:00' || dia.horario_inicio !== '00:00') {
                      return (
                        <p key={dia.id}>
                          {dia.dia}: {dia.horario_inicio} às {dia.horario_fim}
                        </p>
                      );
                    }
                  })
                ) : (
                  <p>Não informado</p>
                )}
              </div>
            )}

            {vaga?.turno?.length > 0 && (
              <>
                <div>
                  <h3>Dias e Horários</h3>
                  <p>
                    {vaga?.horario_comercial === 'especifico' &&
                      `${vaga?.horario_especifico} - ${vaga?.horario_observacao}`}
                    {vaga?.horario_comercial === 'segunda-sexta' && 'Segunda à Sexta'}
                    {vaga?.horario_comercial === 'segunda-sabado' && 'Segunda à Sábado'}
                  </p>
                </div>
                <div>
                  <h3>Turno</h3>
                  <p>{filterTurnos(vaga?.turno)}</p>
                </div>
              </>
            )}

            <div>
              <h3>Tipo de Vaga</h3>
              <p>{vaga?.tipo_vaga.replace('_', ' ')}</p>
            </div>
          </div>
          <div className="row">
            <div>
              <h3>Escolaridade</h3>
              <p>{vaga?.escolaridade}</p>
            </div>
            <div>
              <h3>Requisitos da vaga</h3>
              <p>{vaga?.observacao}</p>
            </div>
          </div>
          <S.Separator />

          <h2>Benefícios</h2>
          <div className="row">
            <div>
              <h3>Vale Transporte</h3>
              <p>
                <strong>{vaga?.vale_transporte ? 'SIM' : 'NÃO'}</strong>
              </p>
              <p>
                {Number(vaga?.valor_transporte).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  style: 'currency',
                  currency: 'BRL',
                })}{' '}
                / dia
              </p>
            </div>
            <div>
              <h3>Vale Alimentação/Refeição</h3>
              <p>
                <strong>{vaga?.vale_alimentacao ? 'SIM' : 'NÃO'}</strong>
              </p>
              <p>
                {Number(vaga?.valor_alimentacao).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  style: 'currency',
                  currency: 'BRL',
                })}{' '}
                / dia
              </p>
            </div>
            <div>
              <h3>Assistência Médica</h3>
              <p>
                <strong>{vaga?.assistencia_medica ? 'SIM' : 'NÃO'}</strong>
              </p>
              <p>{vaga?.obs_assistencia_medica}</p>
            </div>
          </div>
          <S.Separator />
        </S.Curriculo>
      </TemplateAdmin>

      {isLoading ? <Loading text="Carregando dados..." /> : null}
    </>
  );
};
